







































import { Component, Vue, Prop } from "vue-property-decorator";
import { GetPalmReport } from "@/request/research";
import Report from "../components//report.vue";
@Component({
  components: {
    Report,
  },
})
export default class Name extends Vue {
  private id: any = "";
  private type: any = "标注报告";
  private data: any = {};
  private data1: any = {
    认证号: "1234567890",
    来源: "民族医特色诊疗数据库-傣医班",
    标注完成时间: "2024-01-12 12:21:34",
    审核完成时间: "2024-01-12 12:21:34",
    创建人: "张三",
    标注人: "李科",
    审核人: "刘武",
    得分: 80,
    student: {
      age: 0,
      l_palm_image: [
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/b1b16897f26b4539bd005c1a54440439_1.png",
      ],
      labels: [],
      left_palm: {
        手掌: {
          手掌分区: {
            乾宫: [],
            兑宫: [],
            坎宫: [],
            坤宫: ["黑", "红"],
            巽宫: ["淡红", "淡白"],
            明堂: [],
            离宫: ["淡白", "紫"],
            艮宫: [],
            震宫: ["五角星纹", "井字纹"],
          },
          掌型: {
            掌型: ["原始型", "鼓槌型", "硬手"],
          },
          掌纹: {
            "10线": "有",
            "11线": ["有", "过长"],
            "12线": ["有"],
            "13线": "有",
            "14线": "有",
            "1线": ["正常", "畸断"],
            "2线": ["起点抬高", "井字纹"],
            "3线": ["正常", "五角星纹", "干扰线"],
            "4线": ["无", "切入1线"],
            "5线": "有",
            "6线": ["深长", "切入3线"],
            "7线": ["切入1线"],
            "8线": "有",
            "9线": "无",
            三星高照: "无",
            乳腺纹: "有",
            咽区: ["十字纹", "三角形纹"],
            天庭十字纹: "无",
            孔子眼: "无",
            暴怒指: "有",
            艮震线: "有",
            血脂邱: "有",
          },
          掌色: {
            掌色: ["淡红色", "青色"],
          },
        },
        专家建议: {
          专家建议: "",
          按诊断分: {
            中医疾病: [],
            中医证型: [],
            疾病分型: "",
            病程: "",
            西医疾病: [],
          },
          诊断评分: {
            total_score: "",
            脉络大小: "",
            脉络弯曲度: "",
            脉络末端斑点: "",
            脉络走向: "",
            脉络颜色: "",
            黑睛的改变: "",
          },
        },
      },
      name: "元气森林",
      palm_id: "palm663dbc719e752a00152b7087",
      patient_id: "2e880a3b294b",
      project_id: "pro622ededf9e752a037f8daa62",
      r_palm_image: [
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/04e0dbc593b541b784cac9d4a6eb4731_2.png",
      ],
      right_palm: {
        手掌: {
          手掌分区: {
            乾宫: ["环形纹"],
            兑宫: ["淡红"],
            坎宫: ["淡红"],
            坤宫: ["黑"],
            巽宫: ["正常", "淡红"],
            明堂: ["红"],
            离宫: ["淡白"],
            艮宫: ["十字纹"],
            震宫: ["黑"],
          },
          掌型: {
            掌型: ["四方型", "软手"],
          },
          掌纹: {
            "10线": "有",
            "11线": ["无", "过长"],
            "12线": ["有"],
            "13线": "无",
            "14线": "无",
            "1线": ["畸断", "延长至指缝", "十字纹"],
            "2线": ["起点抬高", "井字纹", "环形纹"],
            "3线": [
              "弧度过大",
              "五角星纹",
              "三角形纹",
              "方格纹",
              "岛型纹",
              "干扰线",
            ],
            "4线": ["浅短", "切入1线"],
            "5线": "有",
            "6线": ["无"],
            "7线": ["无"],
            "8线": "有",
            "9线": "无",
            三星高照: "",
            乳腺纹: "无",
            咽区: [],
            天庭十字纹: "",
            孔子眼: "无",
            暴怒指: "有",
            艮震线: "",
            血脂邱: "无",
          },
          掌色: {
            掌色: ["白色"],
          },
        },
        专家建议: {
          专家建议:
            "虹膜的密度高，具有较好的厚度，提示人抗病能力强，精力体力充足，患病后康复快，容易病愈。",
          按诊断分: {
            中医疾病: [
              {
                id: "7．9",
                名称: "胃痞",
              },
            ],
            中医证型: [
              {
                id: "ABRD20",
                名称: "热毒壅盛证",
              },
              {
                id: "ABRD60",
                名称: "热毒凝结证",
              },
            ],
            疾病分型: "Ⅱ型",
            病程: "第Ⅱ阶段",
            西医疾病: [
              {
                id: "R10.102",
                名称: "胃痛",
              },
              {
                id: "K29.701",
                名称: "残胃炎",
              },
              {
                id: "K92.201",
                名称: "胃出血",
              },
              {
                id: "K31.700",
                名称: "胃息肉",
              },
              {
                id: "K31.400",
                名称: "胃憩室",
              },
              {
                id: "K31.811",
                名称: "胃下垂",
              },
              {
                id: "K29.700",
                名称: "胃炎",
              },
            ],
          },
          诊断评分: {
            total_score: "",
            脉络大小: "",
            脉络弯曲度: "",
            脉络末端斑点: "",
            脉络走向: "",
            脉络颜色: "",
            黑睛的改变: "",
          },
        },
      },
      sex: "",
      source: "测试库",
      zhenci: 1,
      zhenci_id: "663dbc719e752a00152b7084",
      专家建议: {
        专家建议: "地方不规范不过分吧",
        按诊断分: {
          中医疾病: [
            {
              id: "7．13",
              名称: "胃反",
            },
          ],
          中医证型: [
            {
              id: "ABRD50",
              名称: "热毒蕴肤证",
            },
          ],
          疾病分型: "Ⅸ型",
          病程: "第Ⅹ阶段",
          西医疾病: [
            {
              id: "K29.701",
              名称: "残胃炎",
            },
          ],
        },
      },
      检测时间: "2024-05-10 14:19:29",
      轨迹: {},
    },
    teacher: {
      age: 0,
      l_palm_image: [
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/b1b16897f26b4539bd005c1a54440439_1.png",
      ],
      labels: [],
      left_palm: {
        手掌: {
          手掌分区: {
            乾宫: ["红", "淡白", "淡红"],
            兑宫: ["红", "淡白", "淡红"],
            坎宫: ["红", "淡红", "正常"],
            坤宫: ["正常", "淡红", "淡白"],
            巽宫: ["淡白", "红", "紫"],
            明堂: ["红", "淡白", "正常"],
            离宫: ["紫", "淡白", "正常"],
            艮宫: ["红", "淡白", "淡红"],
            震宫: ["淡白", "淡红", "正常"],
          },
          掌型: {
            掌型: ["四方型", "鼓槌型"],
          },
          掌纹: {
            "10线": "有",
            "11线": ["有", "短"],
            "12线": ["有"],
            "13线": ["有", "无"],
            "14线": "有",
            "1线": ["延长至指缝", "十字纹", "三角形纹"],
            "2线": ["起点抬高", "平直", "井字纹"],
            "3线": ["弧度过大", "岛纹", "五角星纹"],
            "4线": ["深长", "浅短", "切入1线"],
            "5线": "有",
            "6线": ["深长", "切入3线"],
            "7线": ["切入1线", "有"],
            "8线": "有",
            "9线": "有",
            三星高照: "有",
            乳腺纹: "有",
            咽区: ["十字纹", "三角形纹"],
            天庭十字纹: "有",
            孔子眼: "有",
            暴怒指: "有",
            艮震线: "有",
            血脂邱: "有",
          },
          掌色: {
            掌色: ["红色", "青色"],
          },
        },
        专家建议: {
          专家建议: "地方不规范不过分吧",
          按诊断分: {
            中医疾病: [
              {
                id: "7．13",
                名称: "胃反",
              },
            ],
            中医证型: [
              {
                id: "ABRD50",
                名称: "热毒蕴肤证",
              },
            ],
            疾病分型: "Ⅸ型",
            病程: "第Ⅹ阶段",
            西医疾病: [
              {
                id: "K29.701",
                名称: "残胃炎",
              },
            ],
          },
        },
      },
      name: "元气森林",
      palm_id: "palm663dbc719e752a00152b7087",
      patient_id: "2e880a3b294b",
      project_id: "pro622ededf9e752a037f8daa62",
      r_palm_image: [
        "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/qh/file/file/04e0dbc593b541b784cac9d4a6eb4731_2.png",
      ],
      right_palm: {
        手掌: {
          手掌分区: {
            乾宫: [],
            兑宫: [],
            坎宫: [],
            坤宫: [],
            巽宫: [],
            明堂: [],
            离宫: [],
            艮宫: [],
            震宫: [],
          },
          掌型: {
            掌型: ["原始型", "柔弱型"],
          },
          掌纹: {
            "10线": "",
            "11线": [],
            "12线": ["深长"],
            "13线": "",
            "14线": "",
            "1线": [],
            "2线": [],
            "3线": [],
            "4线": [],
            "5线": "",
            "6线": ["切入2线"],
            "7线": [],
            "8线": "",
            "9线": "",
            三星高照: "",
            乳腺纹: "",
            咽区: [],
            天庭十字纹: "",
            孔子眼: "",
            暴怒指: "",
            艮震线: "",
            血脂邱: "",
          },
          掌色: {
            掌色: [],
          },
        },
        专家建议: {
          专家建议: "地方不规范不过分吧",
          按诊断分: {
            中医疾病: [
              {
                id: "7．13",
                名称: "胃反",
              },
            ],
            中医证型: [
              {
                id: "ABRD50",
                名称: "热毒蕴肤证",
              },
            ],
            疾病分型: "Ⅸ型",
            病程: "第Ⅹ阶段",
            西医疾病: [
              {
                id: "K29.701",
                名称: "残胃炎",
              },
            ],
          },
        },
      },
      sex: "",
      source: "测试库",
      zhenci: 1,
      zhenci_id: "663dbc719e752a00152b7084",
      专家建议: {
        专家建议: "地方不规范不过分吧",
        按诊断分: {
          中医疾病: [
            {
              id: "7．13",
              名称: "胃反",
            },
          ],
          中医证型: [
            {
              id: "ABRD50",
              名称: "热毒蕴肤证",
            },
          ],
          疾病分型: "Ⅸ型",
          病程: "第Ⅹ阶段",
          西医疾病: [
            {
              id: "K29.701",
              名称: "残胃炎",
            },
          ],
        },
      },
      检测时间: "2024-05-10 14:19:29",
      轨迹: {},
    },
  };
  private changeTab(item: any) {
    this.type = item;
  }
  private getDetail() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        palm_id: this.id,
      },
    };
    GetPalmReport(this, params, loading)
      .then((data: any) => {
        loading.close();
        // 下面是为了跟目图的格式一致
        if (data.student.left_palm) {
          data.student.left_palm["手掌"] = data.student.left_palm;
          data.student.right_palm["手掌"] = data.student.right_palm;
        }
        if (data.teacher.left_palm) {
          data.teacher.left_palm["手掌"] = data.teacher.left_palm;
          data.teacher.right_palm["手掌"] = data.teacher.right_palm;
        }
        this.data = data;
      })
      .catch(() => {
        loading.close();
      });
  }
  private mounted() {
    this.id = this.$route.query.id;
    this.getDetail();
  }
}
