




















































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Prop()
  private type: any;
  private readImg(url: any) {
    window.open(url);
  }
  private tree: any = [
    {
      isHeader: true,
      children: [
        {
          type: "text",
          name: "手掌",
          colspan: 2,
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "掌型",
          part: "手掌",
          key1: "掌型",
          key2: "掌型",
          colspan: 2,
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "掌色",
          part: "手掌",
          key1: "掌色",
          key2: "掌色",
          colspan: 2,
        },
      ],
    },
    {
      children: [
        {
          name: "掌纹",
          rowspan: 22,
        },
        {
          type: "多选",
          name: "1线",
          part: "手掌",
          key1: "掌纹",
          key2: "1线",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "2线",
          part: "手掌",
          key1: "掌纹",
          key2: "2线",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "3线",
          part: "手掌",
          key1: "掌纹",
          key2: "3线",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "4线",
          part: "手掌",
          key1: "掌纹",
          key2: "4线",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "5线",
          part: "手掌",
          key1: "掌纹",
          key2: "5线",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "6线",
          part: "手掌",
          key1: "掌纹",
          key2: "6线",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "7线",
          part: "手掌",
          key1: "掌纹",
          key2: "7线",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "8线",
          part: "手掌",
          key1: "掌纹",
          key2: "8线",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "9线",
          part: "手掌",
          key1: "掌纹",
          key2: "9线",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "10线",
          part: "手掌",
          key1: "掌纹",
          key2: "10线",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "11线",
          part: "手掌",
          key1: "掌纹",
          key2: "11线",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "12线",
          part: "手掌",
          key1: "掌纹",
          key2: "12线",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "13线",
          part: "手掌",
          key1: "掌纹",
          key2: "13线",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "14线",
          part: "手掌",
          key1: "掌纹",
          key2: "14线",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "天庭十字纹",
          part: "手掌",
          key1: "掌纹",
          key2: "天庭十字纹",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "艮震线",
          part: "手掌",
          key1: "掌纹",
          key2: "艮震线",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "咽区",
          part: "手掌",
          key1: "掌纹",
          key2: "咽区",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "三星高照",
          part: "手掌",
          key1: "掌纹",
          key2: "三星高照",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "乳腺纹",
          part: "手掌",
          key1: "掌纹",
          key2: "乳腺纹",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "血脂邱",
          part: "手掌",
          key1: "掌纹",
          key2: "血脂邱",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "孔子眼",
          part: "手掌",
          key1: "掌纹",
          key2: "孔子眼",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "暴怒指",
          part: "手掌",
          key1: "掌纹",
          key2: "暴怒指",
        },
      ],
    },
    {
      children: [
        {
          name: "手掌分区",
          rowspan: 9,
        },
        {
          type: "多选",
          name: "巽宫",
          part: "手掌",
          key1: "手掌分区",
          key2: "巽宫",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "离宫",
          part: "手掌",
          key1: "手掌分区",
          key2: "离宫",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "坤宫",
          part: "手掌",
          key1: "手掌分区",
          key2: "坤宫",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "震宫",
          part: "手掌",
          key1: "手掌分区",
          key2: "震宫",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "明堂",
          part: "手掌",
          key1: "手掌分区",
          key2: "明堂",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "兑宫",
          part: "手掌",
          key1: "手掌分区",
          key2: "兑宫",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "艮宫",
          part: "手掌",
          key1: "手掌分区",
          key2: "艮宫",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "坎宫",
          part: "手掌",
          key1: "手掌分区",
          key2: "坎宫",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          name: "乾宫",
          part: "手掌",
          key1: "手掌分区",
          key2: "乾宫",
        },
      ],
    },
    {
      isHeader: true,
      children: [
        {
          type: "text",
          name: "综合建议",
          colspan: 2,
        },
      ],
    },
    {
      children: [
        {
          name: "按诊断分",
          rowspan: 5,
        },
        {
          type: "多选",
          contentType: "名称",
          name: "西医疾病",
          part: "专家建议",
          key1: "按诊断分",
          key2: "西医疾病",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          contentType: "名称",
          name: "中医疾病",
          part: "专家建议",
          key1: "按诊断分",
          key2: "中医疾病",
        },
      ],
    },
    {
      children: [
        {
          type: "多选",
          contentType: "名称",
          name: "中医证型",
          part: "专家建议",
          key1: "按诊断分",
          key2: "中医证型",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "疾病分型",
          part: "专家建议",
          key1: "按诊断分",
          key2: "疾病分型",
        },
      ],
    },
    {
      children: [
        {
          type: "单选",
          name: "病程",
          part: "专家建议",
          key1: "按诊断分",
          key2: "病程",
        },
      ],
    },
    {
      children: [
        {
          type: "建议",
          name: "综合分析",
          part: "专家建议",
          key1: "专家建议",
          colspan: 2,
        },
      ],
    },
  ];
  /**
   * @description 获取需要显示的数据
   */
  private getValue(item: any, position: any, author: any) {
    let str: any = "";
    if (item.type == "标注") {
      let imgs: any = [];
      if (position == "left_palm") {
        imgs = this.data[author].l_palm_image;
      } else if (position == "right_palm") {
        imgs = this.data[author].r_palm_image;
      }
      let d: any = [];
      if (
        this.data[author] &&
        this.data[author][item.part] &&
        this.data[author][item.part][item.key1]
      ) {
        d = this.data[author][item.part][item.key1];
      }
      if (d.length > 0) {
        imgs.forEach((ele: any) => {
          const index: any = d.findIndex((val: any) => {
            return val.id == ele;
          });
          if (index !== -1) {
            str = item.subName + "(" + d[index].data.area.toFixed(2) + "px²)";
          }
        });
      }
    } else if (item.type == "诊断评分") {
      if (
        this.data[author] &&
        this.data[author][position] &&
        this.data[author][position][item.part] &&
        this.data[author][position][item.part][item.key1] &&
        this.data[author][position][item.part][item.key1][item.key2] &&
        this.data[author][position][item.part][item.key1][item.key2].total_score
      ) {
        const value: any =
          this.data[author][position][item.part][item.key1][item.key2]
            .total_score;
        str = value + "分";
      }
    } else if (item.type == "建议") {
      if (
        this.data[author] &&
        this.data[author][position] &&
        this.data[author][position][item.part] &&
        this.data[author][position][item.part][item.key1]
      ) {
        const value: any = this.data[author][position][item.part][item.key1];
        str = value;
      }
    } else {
      if (
        this.data[author] &&
        this.data[author][position] &&
        this.data[author][position][item.part] &&
        this.data[author][position][item.part][item.key1] &&
        this.data[author][position][item.part][item.key1][item.key2] &&
        this.data[author][position][item.part][item.key1][item.key2].length > 0
      ) {
        const value: any =
          this.data[author][position][item.part][item.key1][item.key2];
        if (value && value.length > 0 && item.type == "多选") {
          if (item.contentType) {
            // 是obj数组
            str = "";
            value.forEach((obj: any, objIndex: any) => {
              if (objIndex !== 0) {
                str += "、";
              }
              str += obj[item.contentType];
            });
          } else {
            str = value.join("、");
          }
        } else if (value && item.type == "单选") {
          str = value;
        } else {
          str = "";
        }
      }
    }
    return str;
  }
  /**
   * @description 判断学生和教师的答案是否一致
   */
  private ifIdentical(item: any, position: any) {
    let student: any;
    let teacher: any;
    let result: any = true;
    if (item.type == "多选") {
      student = [];
      teacher = [];
    } else {
      student = "";
      teacher = "";
    }
    if (item.type == "标注") {
      let imgs: any = [];
      if (position == "left_palm") {
        imgs = this.data["student"].l_palm_image;
      } else if (position == "right_palm") {
        imgs = this.data["student"].r_palm_image;
      }
      let d: any = [];
      let d2: any = [];
      if (
        this.data["student"] &&
        this.data["student"][item.part] &&
        this.data["student"][item.part][item.key1]
      ) {
        d = this.data["student"][item.part][item.key1];
      }
      if (d.length > 0) {
        imgs.forEach((ele: any) => {
          const index: any = d.findIndex((val: any) => {
            return val.id == ele;
          });
          if (index !== -1) {
            student = d[index].data.area.toFixed(2);
          }
        });
      }
      if (
        this.data["teacher"] &&
        this.data["teacher"][item.part] &&
        this.data["teacher"][item.part][item.key1]
      ) {
        d2 = this.data["teacher"][item.part][item.key1];
      }
      if (d2.length > 0) {
        imgs.forEach((e: any) => {
          const index: any = d2.findIndex((val: any) => {
            return val.id == e;
          });
          if (index !== -1) {
            teacher = d2[index].data.area.toFixed(2);
          }
        });
      }
      result = student == teacher ? true : false;
    } else if (item.type == "诊断评分") {
      if (
        this.data["student"] &&
        this.data["student"][position] &&
        this.data["student"][position][item.part] &&
        this.data["student"][position][item.part][item.key1] &&
        this.data["student"][position][item.part][item.key1][item.key2] &&
        this.data["student"][position][item.part][item.key1][item.key2]
          .total_score
      ) {
        student =
          this.data["student"][position][item.part][item.key1][item.key2]
            .total_score;
      }
      if (
        this.data["teacher"] &&
        this.data["teacher"][position] &&
        this.data["teacher"][position][item.part] &&
        this.data["teacher"][position][item.part][item.key1] &&
        this.data["teacher"][position][item.part][item.key1][item.key2] &&
        this.data["teacher"][position][item.part][item.key1][item.key2]
          .total_score
      ) {
        teacher =
          this.data["teacher"][position][item.part][item.key1][item.key2]
            .total_score;
      }
      result = student == teacher ? true : false;
    } else if (item.type == "建议") {
      let student: any = "";
      let teacher: any = "";
      if (
        this.data["student"] &&
        this.data["student"][position] &&
        this.data["student"][position][item.part] &&
        this.data["student"][position][item.part][item.key1]
      ) {
        student = this.data["student"][position][item.part][item.key1];
      }
      if (
        this.data["teacher"] &&
        this.data["teacher"][position] &&
        this.data["teacher"][position][item.part] &&
        this.data["teacher"][position][item.part][item.key1]
      ) {
        teacher = this.data["teacher"][position][item.part][item.key1];
      }
      result = student == teacher ? true : false;
    } else {
      if (
        this.data.student &&
        this.data.student[position] &&
        this.data.student[position][item.part] &&
        this.data.student[position][item.part][item.key1] &&
        this.data.student[position][item.part][item.key1][item.key2] &&
        this.data.student[position][item.part][item.key1][item.key2].length > 0
      ) {
        student = JSON.parse(
          JSON.stringify(
            this.data.student[position][item.part][item.key1][item.key2]
          )
        );
      }
      if (
        this.data.teacher &&
        this.data.teacher[position] &&
        this.data.teacher[position][item.part] &&
        this.data.teacher[position][item.part][item.key1] &&
        this.data.teacher[position][item.part][item.key1][item.key2] &&
        this.data.teacher[position][item.part][item.key1][item.key2].length > 0
      ) {
        teacher = JSON.parse(
          JSON.stringify(
            this.data.teacher[position][item.part][item.key1][item.key2]
          )
        );
      }
      // 判断是否重复
      if (item.type == "单选") {
        result = student == teacher ? true : false;
      } else if (item.type == "多选") {
        // 首先确保两个数组都是数组类型
        if (!Array.isArray(student) || !Array.isArray(teacher)) {
          result = false;
        } else if (student.length !== teacher.length) {
          // 检查两个数组的长度是否相等
          result = false;
        }
        // 对两个数组进行排序
        if (student.length > 0) {
          student.sort();
        }
        if (teacher.length > 0) {
          teacher.sort();
        }

        // 比较排序后的数组元素
        let studentArr: any = "";
        let teachertArr: any = "";
        if (item.contentType) {
          // 是obj数组
          student.forEach((obj: any, objIndex: any) => {
            if (objIndex !== 0) {
              studentArr += "、";
            }
            studentArr += obj[item.contentType];
          });
          teacher.forEach((obj: any, objIndex: any) => {
            if (objIndex !== 0) {
              teachertArr += "、";
            }
            teachertArr += obj[item.contentType];
          });
        } else {
          studentArr = student.join("、");
          teachertArr = teacher.join("、");
        }
        result = studentArr == teachertArr ? true : false;
      }
    }

    return result;
  }
  private mounted() {}
}
